@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* { font-family: 'Orbitron', sans-serif; }

html, body { background-color:#000204; }

.main-frame { margin-bottom: 20px; }

main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  transition: all 250ms ease-out;
}

.width100 {
  width: 100%;
  display: flex;
  justify-content: center;
}

h1 {
  display: block;
  text-align: left;
}

.c016 {
  background-image: linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) ), url(/static/media/space-background.dc858b56.jpg);
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  z-index: 1;
  transition: all 250ms ease-in;
}

.c017 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAYAAAA5ZDbSAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QMaAyMA1SdmlAAAAVRJREFUeNrt26FOw2AUhuFTElzrETNLMNPtJVRVVFbtlnYXKGQFqldANo3EoLDUITazzCxBTNBk53lv4M+XJ/ndKZ52L9uft9eP+Oeqbtgs8O7+cbWO36/PiIgmwd4ojsdIU9n2l7XzNBYZNj9Eos6oTRbcdMAZAwxYgAVYgAVYgAUYsAALsAALsAALMGABFmABFmABFmABBizAAqwFgZ/fv+slHl7q3aobNpn2proujIgo276ep/HgixZgARZgARZgAQYswAIswAIswAIswIAFWIAFWIAFWIABC7AAC7AAC7D+AHZdeN97XRf6ogVYgAVYgAVYgAELsAALsAALsAADFmABFmABFmABFmDAAizAAizAAqxrYNeF973XdaEvWoAFWIAFWIAFGLAAC7AAC7AACzBgARZgARZgARZgAQYswAIswAKsW0p1m1S2/WXtPI1Fhs0nxU1Jj2yxm2sAAAAASUVORK5CYII=);
  position: absolute;
  background-repeat: repeat;
  background-size: 100px 100px;
  background-position: center top;
  background-attachment: fixed;
  z-index: 2;
  transition: all 250ms ease-in;
}

p { margin: 1px; }

h4 { margin: 0; }

.list-item {
  font-size: 18px;
  font-weight: 600;
  color: #27dafd;
}

.list-container { margin-left: 10px; }

.project-list-container { margin: 21px 0px 0px 10px; }

.list-item-container { 
  display: inline-flex; 
  margin: 4px 0px 4px 0px;
}

header.header {
  margin: 20px 0px 40px 0px;
  background: transparent; 
  width: 80vw; 
}

.total-contact-container, .projects-tab-container {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0px;
}

.projects-nav, .contact-nav {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  border: 1px solid;
  width: 100%;
}

.divider { margin-bottom: 14px; }

.nav-item, .project-item, .link {
  margin: 0;
  width:100%;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
}

.data-block-container { margin-bottom: 20px; }

.data-block {
  display: flex;
  align-items: flex-start;
}

.bio-data {
  color: #f8f8ff;
  font-size: 18px;
  font-weight: 100;
}

.answer {
  color: #27dafd;
  font-weight: 500;
  font-size: 20px;
  margin-left: 6px;
}

.bio-page-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  padding:5px;
  margin-top:35px;
}

.total-project-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin: 30px 0px 15px 0px;
}

.project-image-container, .download-resume { margin-top: 15px; }

img.tech-used {
  display: block;
  width: 45px;
  height: 45px;
  padding: 8px;
  margin: 2px;
  transition: all 250ms ease-in-out;
  -webkit-animation: fade-in .75s linear 0s 1 forwards;
          animation: fade-in .75s linear 0s 1 forwards;
}

.link:hover, .nav-item:hover, .project-item:hover {
  background-color: #27dafd;
  color: #010506;
}

.project-link-container { margin-top: -15px; }

.project-link-container, .techs {
  display: flex;
  flex-flow: row wrap;
}

.techs {
  margin: 10px 0px 15px 0px;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.skills { margin-top: 30px; }

.project-info, .bio-info, .contact-info {
  display: flex;
  flex-direction: column;
  width: 50%;
}

div.contact-info { width: 70%; }

.project-links, .separator { font-size: 16px; }

p.project-links > a > span > span:hover { color: #27dafd; }

.separator { margin: 0px 8px 0px 8px; }

img.primary-img, img.resume-image {
  max-height: 330px;
  max-width: 330px;
  display: block;
  padding:10px;
  -webkit-animation: fade-in .75s linear 0s 1 forwards;
          animation: fade-in .75s linear 0s 1 forwards;
}

@-webkit-keyframes fade-in {
  0% {opacity: 0}
  25% {opacity: .25}
  50% {opacity: .5}
  100% {opacity: .85}
}

@keyframes fade-in {
  0% {opacity: 0}
  25% {opacity: .25}
  50% {opacity: .5}
  100% {opacity: .85}
}

.project-tab, .contact-link {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.resume-and-email {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}

input, textarea {
  background-color: #05181d;
  color: #27dafd;
  box-shadow: 1px 1px #f8f8ff;
  margin: 20px 0px 0px 0px;
  width: 100%;
}

.label {
  color: #f8f8ff;
  font-size: 18px;
  margin-bottom: 10px;
}

.email-me { transition: all 250ms ease-in-out; }

a.download-resume, #submit { margin-top: 25px;}

#submit {
  width: 100%;
  text-align: center;
}

#submit:hover, .download-resume:hover {
  opacity: 0.6;
  cursor: pointer;
}

.puffs {
  width: 100%;
  height: 100vh;
}

.tech-label {
  color: #f8f8ff;
  margin-right: 20px;
  font-size: 16px
}

a, a::before, a::after {
  text-decoration: none;
  color: #f8f8ff;
}

a.link { color: #27dafd; }

.soc-med-image {
  max-height: 50px;
  max-width: 50px;
  margin-top: 8px;
  opacity: .8;
}

.contact-form, .resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fuzzy-background {
  background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://i.gifer.com/757a.gif");
  margin: 1px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.message-required, .email-required, .email-success, .soc-med-image {
  display: none;
}

.email-success {
  font-size: 14px;
  margin-top: 10px;
}

.message-required, .email-required {
  align-self: start;
  font-size: 12px;
}

.mute {
  position: absolute;
  font-size: 9px;
  top: 3%;
  left:90%;
  cursor: pointer;
  opacity: 0.7;
}

.speaker {
  height: 14px;
  width: 14px;
}

/* tablet responsiveness */

@media (max-width: 1366px) and (max-height: 1024px) {
  .bio-page-container, .total-project-container, .total-contact-container {
    margin-top: 75px;
  }
  .techs, .skills { margin-top: 35px; }
}
@media (max-width: 1024px) and (max-height: 1366px) {
  .bio-page-container, .total-project-container {
    margin-top: 110px;
  }
  .total-contact-container { margin-top: 40px; }
  .resume-and-email, .epic-beer { flex-direction: column; }
  .epic-beer-info {
    width: 90%;
    margin-bottom: 30px;
  }
  .download-resume { margin-bottom: 50px; }
  #submit { margin-bottom: 45px; }
  .techs { margin-top: 30px; }

}

@media (max-width: 1280px) and (max-height: 950px) {
  .techs {
    margin-top: 20px;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .bio-page-container, .total-project-container {
    flex-direction: column;
    margin-top: 30px;
  }
  .bio-info, .project-info {
    margin-bottom: 30px;
    width: 90%;
  }
  .techs, .skills {
    margin-top: 20px;
    width: 80%;
  }
}

@media (max-width: 1024px) and (max-height: 768px) {
  .bio-page-container, .total-project-container { margin-top: 40px; }
  .techs { margin-top: 15px; }
}

/* phone responsiveness */

@media (max-width: 850px) and (max-height: 850px) {
  .bio-page-container, .total-project-container { flex-direction: column; }
  .bio-image-container, .project-image-container { margin-top: 25px; }
  .bio-info, .project-info { width: 90%; }
  .bio-page-container, .total-contact-container { margin-top: 20px; }
  .total-project-container { margin-top: 0px;}
  .width100 { margin: 10px 0px 25px 0px; }
}

@media (max-width: 460px) {
  .contact-nav { background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://i.gifer.com/757a.gif"); }
  .link { display:none; }
  .soc-med-image { display:inline-flex; }
  img.primary-img { max-width: 250px; }
  .techs { margin: 0px; }
  .email-me { width: 85%; }
} 

@media (max-width: 360px) {
  img.primary-img { max-width: 190px; }
  h4 > span.header-text > span { font-size: 18px; }
  img.resume-image { max-width: 190px; }
}

@media (max-width: 320px) {
  p.bio-data { font-size: 13px; }
  p.answer, h5 > span > span, h5.nav-item { font-size: 16px; }
  .list-item { font-size: 12px; }
  h2 > span > span { font-size: 24px;}
  .project-links, .separator { font-size: 14px; }
  .soc-med-image {
    max-height: 40px;
    max-width: 40px;
  }
}

/* large display responsiveness */

@media (min-width: 1280px) and (min-height: 800px) {
  .total-contact-container { margin-top: 30px; }
  .bio-page-container { margin-top: 60px; }
  .total-project-container { margin-top: 30px;}
  /* .techs { margin-top: 20px; }
  .skills { margin-top: 10px; } */
}

@media (min-width: 1440px) and (min-height: 900px) {
  .techs { margin-top: 60px;}
  .skills { margin-top: 90px;}
}

@media (min-width: 1920px) and (min-height: 1080px) {
  .header-text > span, h5 > span > span, h5.nav-item, .link { font-size: 30px;}
  p.bio-data, .list-item, .label { font-size: 24px; }
  p.answer { font-size: 28px; }
  h2 > span > span { font-size: 36px;}
  .project-links, .separator { font-size: 26px; }
  .tech-label { font-size: 22px;}
  input { height: 30px; }
  img.primary-img, img.resume-image {
    max-height: 430px;
    max-width: 430px;
  }
  img.tech-used {
    height: 60px;
    width: 60px;
  }
  .total-contact-container { margin-top: 60px; }
  .download-resume { margin-top: 35px; }
}

@media (min-width: 2560px) and (min-height: 1600px) {
  .header-text > span, h5 > span > span, h5.nav-item, .link { font-size: 42px;}
  p.bio-data, .list-item { font-size: 34px; }
  p.answer { font-size: 42px; }
  h2 > span > span { font-size: 50px;}
  .project-links, .separator { font-size: 40px; }
  .tech-label { font-size: 36px;}
  img.primary-img, img.resume-image {
    max-height: 680px;
    max-width: 680px;
    height: 680px;
  }
  img.primary-img[alt="epic-beer"] {
    max-height: 680px;
    max-width: 680px;
    height: auto;
    width: 680px;
  }
  img.tech-used {
    height: 88px;
    width: 88px;
  }
  .total-contact-container { margin-top: 60px; }
  .download-resume { margin-top: 35px; }
  input { height: 45px; }
}

@media (min-width: 2560px) and (min-height: 1600px) {
  .bio-page-container, .total-project-container, .total-contact-container {
    margin-top: 100px;
  }
}
